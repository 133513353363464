<template>
  <SiteTemplateV2 v-if="clubVersion === 'v2'">
  <div class="createClass">
    <div class="container">
      <modal-upload :data="data"></modal-upload>
      <modal-categoria></modal-categoria>
      <b-row class="spaceWizzard">
        <b-col cols="12" md="3" class="pt-5" data-anima="top">
          <div class="navigate">
            <div class="title title-v2">
              {{ AllCourses.title }}
              <button
                id="viewModuleLesson"
                class="viewModuleLesson"
                @click="viewCourse()"
              >
                Ver Curso
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.375 8.75L12.5 5.625L9.375 2.5"
                    stroke="#C4C4C4"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                    stroke="#C4C4C4"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/' + cursoAtual"
              class="removeLink"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7956 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H9V16H5C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16H11V14H17C17.7956 14 18.5587 13.6839 19.1213 13.1213C19.6839 12.5587 20 11.7956 20 11V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8946 17.2652 12 17 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V11Z"
                      fill="#333333"
                    />
                  </svg>
                  Dados do Curso
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/' + cursoAtual + '/module'"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.47 16.82L20.47 12.96L17.32 1.37003C17.2519 1.11437 17.0853 0.896104 16.8567 0.76303C16.628 0.629955 16.3559 0.592918 16.1 0.66003L12.23 1.66003C12.1376 1.55767 12.0251 1.47552 11.8995 1.41873C11.7739 1.36194 11.6379 1.33174 11.5 1.33003H1.5C1.23478 1.33003 0.98043 1.43539 0.792893 1.62292C0.605357 1.81046 0.5 2.06481 0.5 2.33003V18.33C0.5 18.5952 0.605357 18.8496 0.792893 19.0371C0.98043 19.2247 1.23478 19.33 1.5 19.33H11.5C11.7652 19.33 12.0196 19.2247 12.2071 19.0371C12.3946 18.8496 12.5 18.5952 12.5 18.33V10.33L14.7 18.55C14.7586 18.7682 14.8893 18.9601 15.0709 19.0945C15.2525 19.2288 15.4742 19.2978 15.7 19.29C15.7864 19.2999 15.8736 19.2999 15.96 19.29L20.79 18C20.9177 17.9659 21.0374 17.9068 21.1421 17.8261C21.2468 17.7454 21.3344 17.6448 21.4 17.53C21.5055 17.3088 21.5302 17.0576 21.47 16.82ZM5.47 17.37H2.47V15.37H5.47V17.37ZM5.47 13.37H2.47V7.37003H5.47V13.37ZM5.47 5.37003H2.47V3.37003H5.47V5.37003ZM10.47 17.37H7.47V15.37H10.47V17.37ZM10.47 13.37H7.47V7.37003H10.47V13.37ZM10.47 5.37003H7.47V3.37003H10.47V5.37003ZM12.72 3.63003L15.62 2.85003L16.14 4.78003L13.24 5.56003L12.72 3.63003ZM15.31 13.29L13.76 7.49003L16.66 6.71003L18.21 12.51L15.31 13.29ZM16.31 17.15L15.79 15.22L18.69 14.44L19.21 16.37L16.31 17.15Z"
                      fill="#333333"
                    />
                  </svg>
                  Módulos e Aulas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/class/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="26"
                    height="22"
                    viewBox="0 0 26 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                      stroke="#333333"
                      stroke-width="2.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Turmas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/create-certificates/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.43496 22.9609H16.6119H8.43496ZM12.5234 22.9609V16.4194V22.9609ZM19.065 10.6955C19.065 8.10753 19.0609 3.81517 19.0588 2.51862C19.0588 2.30175 18.9727 2.09377 18.8193 1.94042C18.666 1.78708 18.458 1.70093 18.2411 1.70093L6.80417 1.71422C6.58775 1.71421 6.38016 1.80001 6.22688 1.95281C6.07361 2.1056 5.98716 2.31293 5.98648 2.52935C5.98648 4.09216 5.97984 9.13067 5.97984 10.6955C5.97984 13.9806 10.2216 16.4194 12.5214 16.4194C14.8211 16.4194 19.065 13.9806 19.065 10.6955ZM19.065 10.6955C21.4388 10.6955 23.1534 7.79374 23.1534 4.97169V4.154H19.065V10.6955ZM5.98188 4.154H1.89343V4.97169C1.89343 7.79374 3.60803 10.6955 5.98188 10.6955V4.154Z"
                      stroke="#333333"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Certificado
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/vendas/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 6H21"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  Vendas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>

            <div class="spaceBtn">
              <toggle-button
                :sync="true"
                :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                @change="enventSwitchStatus"
                v-model="myDataVariable"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="9" class="pt-5" data-anima="top">
          <div class="containerWizzard">
            <div v-if="toAddClass === false" class="title title-v2">
              Minhas Turmas
              <svg
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                  stroke="#333333"
                  stroke-width="2.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div v-else class="title title-v2">
              {{ textAction }}
              <svg
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 20V18C17 16.9391 16.5786 15.9217 15.8284 15.1716C15.0783 14.4214 14.0609 14 13 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M21 7V13M24 10H18M13.5 6C13.5 8.20914 11.7091 10 9.5 10C7.29086 10 5.5 8.20914 5.5 6C5.5 3.79086 7.29086 2 9.5 2C11.7091 2 13.5 3.79086 13.5 6Z"
                  stroke="#333333"
                  stroke-width="2.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="containerWizzardInputs animationOpacity2">
            <b-table
              :fields="fieldsx"
              show-empty
              small
              stacked="md"
              :items="AllClass"
              :tbody-tr-class="rowClass"
              :filter-included-fields="filterOn"
              v-if="toAddClass === false"
            >
              <template #empty>
                <p class="title text-center-empty">{{ emptyText }}</p>
              </template>
              <template #emptyfiltered>
                <p class="title text-center-empty">{{ emptyText }}</p>
              </template>

              <template #cell(title)="row">
                <div @click="howToEdit(row.item)" class="text pointer">
                  {{ row.item.title }}
                </div>
              </template>
              <template #cell(students_count)="row">
                <div @click="howToEdit(row.item)" class="text pointer">
                  {{ row.item.students_count }} Alunos
                </div>
              </template>
              <template #cell(updated_at)="row">
                <div
                  class="text pointer flexItem"
                  style="white-space: nowrap"
                >
                  {{ row.item.updated_at | moment("DD/MM/YYYY HH:mm") }}
                </div>
              </template>
              <template #cell(created_at)="row">
                <div class="actionData">
                  <div class="editar" @click="howToEdit(row.item)">
                    Editar
                  </div>
                  <div class="deletar" @click="deleteClass(row.item.id)">
                    Deletar
                  </div>
                </div>
              </template>
            </b-table>
            <div v-else>
              <div class="spaceInputs">
                <b-form-group label="Nome da Turma" label-for="name-aula">
                  <b-form-input
                    v-model="nameClass"
                    placeholder="Nome da Turma"
                  ></b-form-input>
                </b-form-group>
              </div>
              <span v-if="toEditId !== ''">
                <div class="spaceInputs">
                  <b-form-group
                    label="Liberação da Turma"
                    label-for="name-aula"
                  >
                    <button @click="openModalTurma" class="btnListModule">
                      Configurar Turma
                    </button>
                  </b-form-group>
                </div>
                <div class="spaceInputs">
                  <b-form-group
                    label="Liberação de Módulos"
                    label-for="name-aula"
                  >
                    <button @click="openModalModule" class="btnListModule">
                      Configurar Modulos
                    </button>
                  </b-form-group>
                </div>
                <div class="spaceInputs">
                  <b-form-group
                    label="Liberação de Aulas"
                    label-for="name-aula"
                  >
                    <button @click="openModalLesson" class="btnListModule">
                      Configurar Aulas
                    </button>
                  </b-form-group>
                </div>
                <div class="spaceInputs">
                  <b-form-group
                    label="Liberação do Telegram"
                    label-for="name-aula"
                  >
                    <button @click="openModalTelegram" class="btnListModule">
                      Configurar Telegram
                    </button>
                  </b-form-group>
                </div>
              </span>

              <div class="spaceInputs">
                <button class="btn-cancela" @click.prevent="notSaveClass()">
                  Voltar
                </button>
                <button class="btn-criar" @click.prevent="createClass()">
                  Salvar esta Turma
                </button>
              </div>
            </div>
          </div>
          <div class="spaceInputs" v-if="toAddClass === false">
            <b-form-group class="paddingNewClass">
              <div class="btn-newClass" @click="addNewClass()">
                <p class="text">Adicionar mais uma Turma</p>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal id="modal-turma-liberation" hide-footer size="xl">
      <div class="AllContent">
        <div class="flexHeader">
          <div>
            <div class="title title-v2">Meu Curso</div>
            <div class="subtitle">Configure a liberação do Curso</div>
          </div>
        </div>
        <div class="flexHeader2">
          <div></div>
          <!-- <div class="totalModules">{{ totalModules }} Cursos encontrados</div> -->
        </div>

        <div class="tableModule">
          <div class="header">
            <div>Nome do Curso</div>
            <div>Tipo</div>
            <div>Data Liberação</div>
            <div>Data Finalização</div>
            <div>Ação</div>
          </div>
          <div class="body">
            <div class="text">{{ AllCourses.title }}</div>
            <div
              class="text"
              v-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
            >
              Dias Após Inscrição
            </div>
            <div
              class="text"
              v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
            >
              Data Fixa
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="text" v-if="AllCoursesLiberation.rule === null">
              Liberado
            </div>
            <div
              class="text"
              v-else-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
            >
              {{ AllCoursesLiberation.dinamicDate.daysToStart }} Dias
            </div>
            <div
              class="text"
              v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
            >
              {{
                AllCoursesLiberation.fixedDateStart
                  | moment("DD/MM/YYYY HH:mm")
              }}
            </div>

            <div class="text" v-if="AllCoursesLiberation.rule === null">
              Liberado
            </div>
            <div
              class="text"
              v-else-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
            >
              {{ AllCoursesLiberation.dinamicDate.daysToEnd }} Dias
            </div>
            <div
              class="text"
              v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
            >
              {{
                AllCoursesLiberation.fixedDateEnd | moment("DD/MM/YYYY HH:mm")
              }}
            </div>

            <div class="editar" @click="openCursoEdit(AllCourses)">
              Gerenciar
            </div>
          </div>
        </div>
      </div>
      <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
        <div class="modalModuleLiberation">
          <svg
            @click="editModuleLiberation = false"
            class="close"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
              stroke="#B5B9C5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="title title-v2">Liberação do Curso</div>
          <div class="space animationOpacity2">
            <b-form-group label="Tipo de Liberação" label-for="name-module">
              <b-form-select
                v-model="selectedType"
                :options="optionsType"
                @input="resetInputs"
              ></b-form-select>
            </b-form-group>
          </div>
          <br />
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'daysAfterInscription'"
          >
            <b-form-group label="Data de Liberação" label-for="name-module">
              <b-form-input
                v-mask="'####'"
                v-model="daysAfterInscription"
                placeholder="Ex: 15 Dias"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'daysAfterInscription'"
          >
            <b-form-group
              label="Data de Finalização(Opcional)"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'####'"
                v-model="daysAfterInscriptionEnd"
                placeholder="Ex: 30 Dias"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'fixedDate'"
          >
            <b-form-group label="Data de Liberação" label-for="name-module">
              <b-form-input
                v-mask="'##/##/#### ##:##'"
                v-model="dataInit"
                placeholder="Ex: 15/04/2021 15:45"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'fixedDate'"
          >
            <b-form-group
              label="Data de Finalização(Opcional)"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'##/##/#### ##:##'"
                v-model="dataFim"
                placeholder="Ex: 15/05/2021 15:45"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="space">
            <button class="btn-salvar" @click="salvarEditarCourse">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-modules-liberation" hide-footer size="xl">
      <div class="AllContent">
        <div class="flexHeader">
          <div>
            <div class="title title-v2">Listagem de Módulos</div>
            <div class="subtitle">Configure suas liberações</div>
          </div>
        </div>
        <div class="flexHeader2">
          <div></div>
          <div class="totalModules">
            {{ totalModules }} Módulos encontrados
          </div>
        </div>

        <div class="tableModule">
          <div class="header">
            <div>Nome do Módulo</div>
            <div>Tipo</div>
            <div>Data Liberação</div>
            <div>Data Finalização</div>
            <div>Ação</div>
          </div>
          <div class="body" v-for="modules in AllModules" :key="modules.id">
            <div class="text">{{ modules.title }}</div>
            <div class="text" v-if="modules.rule === null">Liberado</div>
            <div
              class="text"
              v-else-if="modules.rule.rule === 'daysAfterInscription'"
            >
              Dias Após Inscrição
            </div>
            <div class="text" v-else-if="modules.rule.rule === 'fixedDate'">
              Data Fixa
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="text" v-if="modules.rule === null">Liberado</div>
            <div
              class="text"
              v-else-if="modules.rule.rule === 'daysAfterInscription'"
            >
              {{ modules.rule.dinamicDate.daysToStart }} Dias
            </div>
            <div class="text" v-else-if="modules.rule.rule === 'fixedDate'">
              {{ modules.rule.fixedDateStart | moment("DD/MM/YYYY HH:mm") }}
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="text" v-if="modules.rule === null">Liberado</div>
            <div
              class="text"
              v-else-if="
                modules.rule.rule === 'daysAfterInscription' &&
                modules.rule.dinamicDate.daysToEnd === 0
              "
            >
              {{ modules.rule.dinamicDate.daysToEnd }} Dias
            </div>
            <div
              class="text"
              v-else-if="modules.rule.rule === 'daysAfterInscription'"
            >
              {{ modules.rule.dinamicDate.daysToEnd }} Dias
            </div>
            <div
              class="text"
              v-else-if="
                modules.rule.rule === 'fixedDate' && modules.rule.fixedDateEnd
              "
            >
              {{ modules.rule.fixedDateEnd | moment("DD/MM/YYYY HH:mm") }}
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="editar" @click="openModuleEdit(modules)">
              Gerenciar
            </div>
          </div>
        </div>
      </div>
      <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
        <div class="modalModuleLiberation">
          <svg
            @click="editModuleLiberation = false"
            class="close"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
              stroke="#B5B9C5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="title title-v2">Liberação de Módulo</div>
          <div class="space animationOpacity2">
            <b-form-group label="Tipo de Liberação" label-for="name-module">
              <b-form-select
                v-model="selectedType"
                :options="optionsType"
              ></b-form-select>
            </b-form-group>
          </div>
          <br />
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'daysAfterInscription'"
          >
            <b-form-group label="Data de Liberação" label-for="name-module">
              <b-form-input
                v-mask="'####'"
                v-model="daysAfterInscription"
                placeholder="Ex: 15 Dias"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'daysAfterInscription'"
          >
            <b-form-group
              label="Data de Finalização(Opcional)"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'####'"
                v-model="daysAfterInscriptionEnd"
                placeholder="Ex: 30 Dias"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'fixedDate'"
          >
            <b-form-group label="Data de Liberação" label-for="name-module">
              <b-form-input
                v-mask="'##/##/#### ##:##'"
                v-model="dataInit"
                placeholder="Ex: 15/04/2021 15:45"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'fixedDate'"
          >
            <b-form-group
              label="Data de Finalização(Opcional)"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'##/##/#### ##:##'"
                v-model="dataFim"
                placeholder="Ex: 15/05/2021 15:45"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="space">
            <button class="btn-salvar" @click="salvarEditarModule">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-lesson-liberation" hide-footer size="xl">
      <div class="AllContent">
        <div class="flexHeader">
          <div>
            <div class="title title-v2">Listagem de Aulas</div>
            <div class="subtitle">Configure suas liberações</div>
          </div>
        </div>
        <div class="flexHeader2">
          <div>
            <b-form-select
              @change="selectedModuleinLesson"
              v-model="selectedModuleToClass"
              class="select-filter-options"
            >
              <b-form-select-option
                v-for="(options, x) in AllModules"
                :key="x"
                :value="options.id"
                >{{ options.title }}</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="totalModules">{{ totalLesson }} Aulas encontradas</div>
        </div>

        <div class="tableModule">
          <div class="header">
            <div>Nome da Aula</div>
            <div>Tipo</div>
            <div>Data Liberação</div>
            <div>Data Finalização</div>
            <div>Ação</div>
          </div>
          <div class="body" v-for="lesson in AllLessons" :key="lesson.id">
            <div class="text">{{ lesson.title }}</div>

            <div class="text" v-if="lesson.rule == null">Liberado</div>
            <div
              class="text"
              v-else-if="lesson.rule.rule === 'daysAfterInscription'"
            >
              Dias Após Inscrição
            </div>
            <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
              Data Fixa
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="text" v-if="lesson.rule === null">Liberado</div>
            <div
              class="text"
              v-else-if="lesson.rule.rule === 'daysAfterInscription'"
            >
              {{ lesson.rule.dinamicDate.daysToStart }} Dias
            </div>
            <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
              {{ lesson.rule.fixedDateStart | moment("DD/MM/YYYY HH:mm") }}
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="text" v-if="lesson.rule === null">Liberado</div>
            <div
              class="text"
              v-else-if="lesson.rule.rule === 'daysAfterInscription'"
            >
              {{ lesson.rule.dinamicDate.daysToEnd }} Dias
            </div>
            <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
              {{ lesson.rule.fixedDateEnd | moment("DD/MM/YYYY HH:mm") }}
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="editar" @click="openLessonEdit(lesson)">
              Gerenciar
            </div>
          </div>
        </div>
      </div>
      <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
        <div class="modalModuleLiberation">
          <svg
            @click="editModuleLiberation = false"
            class="close"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
              stroke="#B5B9C5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="title title-v2">Liberação de Aulas</div>
          <div class="space animationOpacity2">
            <b-form-group label="Tipo de Liberação" label-for="name-module">
              <b-form-select
                v-model="selectedType"
                :options="optionsType"
              ></b-form-select>
            </b-form-group>
          </div>
          <br />
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'daysAfterInscription'"
          >
            <b-form-group label="Data de Liberação" label-for="name-module">
              <b-form-input
                v-mask="'####'"
                v-model="daysAfterInscription"
                placeholder="Ex: 15 Dias"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'daysAfterInscription'"
          >
            <b-form-group
              label="Data de Finalização(Opcional)"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'####'"
                v-model="daysAfterInscriptionEnd"
                placeholder="Ex: 30 Dias"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'fixedDate'"
          >
            <b-form-group label="Data de Liberação" label-for="name-module">
              <b-form-input
                v-mask="'##/##/#### ##:##'"
                v-model="dataInit"
                placeholder="Ex: 15/04/2021 15:45"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'fixedDate'"
          >
            <b-form-group
              label="Data de Finalização(Opcional)"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'##/##/#### ##:##'"
                v-model="dataFim"
                placeholder="Ex: 15/05/2021 15:45"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="space">
            <button class="btn-salvar" @click="salvarEditarLesson">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-telegram-liberation" hide-footer size="xl">
      <div class="AllContent">
        <div class="flexHeader">
          <div>
            <div class="title title-v2">Integração com o Telegram</div>
            <div class="subtitle">Configure suas liberações</div>
            <button
              v-if="showBtnTelegram && !AllTelegramIntegrations.length"
              @click="initTelegramIntegration"
              class="btn-criar"
            >
              Iniciar integração
            </button>
          </div>
        </div>

        <div
          v-if="
            AllTelegramIntegrations.filter(
              (i) => i.status === 'draft' && !i.rule
            ).length >= 1
          "
        >
          <div class="body" style="display: flex; flex-direction: column">
            <h3 class="title mt-3">
              Instruções para continuar a integração:
            </h3>
            <h4 class="subtitle mt-2">
              1. Criar um grupo ou canal no Telegram
            </h4>
            <h4 class="subtitle mt-2">
              2. Configurar como canal ou grupo privado
            </h4>
            <h4 class="subtitle mt-2">
              3. Adicione o bot "Greenn", com o username "greenn_relacionamento_bot" ao seu grupo do
              telegram, com a permissão de admin!
            </h4>
            <h4 class="subtitle mt-2">
              4. Envie o código "{{
                AllTelegramIntegrations.filter((i) => i.status === "draft")[0]
                  .code_integration
              }}" no seu grupo para confirmar a integração!
            </h4>

            <h4 class="subtitle mt-2">
              5. Configure a data de Liberação (Opcional)
            </h4>

            <div class="space animationOpacity2">
              <b-form-group label="Tipo de Liberação" label-for="name-module">
                <b-form-select
                  v-model="selectedType"
                  :options="optionsType"
                ></b-form-select>
              </b-form-group>
            </div>

            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group label="Data de Liberação" label-for="name-module">
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscription"
                  placeholder="Ex: 15 Dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Data de Finalização(Opcional)"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscriptionEnd"
                  placeholder="Ex: 30 Dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group label="Data de Liberação" label-for="name-module">
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataInit"
                  placeholder="Ex: 15/04/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data de Finalização(Opcional)"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataFim"
                  placeholder="Ex: 15/05/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>

            <button @click="salvarTelegramEdit" class="mt-2 btn-criar">
              Finalizar integração
            </button>
          </div>
        </div>
        <div
          v-if="
            AllTelegramIntegrations.filter((i) => i.status === 'published')
              .length >= 1
          "
          class="tableModule"
        >
          <div class="header">
            <div>Nome</div>
            <div>Tipo</div>
            <div>Data Liberação</div>
            <div>Data Finalização</div>
            <div>Ação</div>
          </div>
          <div
            class="body"
            v-for="telegram in AllTelegramIntegrations"
            :key="telegram.id"
          >
            <div class="text">{{ telegram.name }}</div>

            <div class="text" v-if="telegram.rule === null">Liberado</div>
            <div class="text" v-else-if="telegram.rule === 'dinamic'">
              Dias Após Inscrição
            </div>
            <div class="text" v-else-if="telegram.rule === 'fixed'">
              Data Fixa
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="text" v-if="telegram.rule === null">Liberado</div>
            <div class="text" v-else-if="telegram.rule === 'dinamic'">
              {{ telegram.dinamic_date.daysToStart }} Dias
            </div>
            <div class="text" v-else-if="telegram.rule === 'fixed'">
              {{
                telegram.dinamic_date.fixedDateStart
                  | moment("DD/MM/YYYY HH:mm")
              }}
            </div>
            <div class="text" v-else>Liberado</div>

            <div class="text" v-if="telegram.rule === null">Liberado</div>
            <div
              class="text"
              v-else-if="
                telegram.rule === 'dinamic' &&
                telegram.dinamic_date.daysToEnd &&
                telegram.dinamic_date.daysToEnd === 0
              "
            >
              {{ telegram.dinamic_date.daysToEnd }} Dias
            </div>
            <div class="text" v-else-if="telegram.rule === 'dinamic'">
              {{ telegram.dinamic_date.daysToEnd }} Dias
            </div>
            <div class="text" v-else-if="telegram.rule === 'fixed'">
              {{
                telegram.dinamic_date.fixedDateEnd
                  | moment("DD/MM/YYYY HH:mm")
              }}
            </div>
            <div class="text" v-else>Liberado</div>
            <div style="display: flex; flex-wrap: nowrap;">
              <div class="editar" @click="removeTelegramIntegration(telegram)">
                Remover
              </div>
              <div v-if="needUpdateBotTegram(telegram)" class="atualizar ml-1" @click="openModalTelegramUpdate(telegram)">
                Atualizar
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            AllTelegramIntegrations.filter(
              (i) => i.status === 'draft' && i.rule
            ).length >= 1
          "
          class="tableModule"
        >
          <div class="body" style="display: flex; flex-direction: column">
            <h3 class="title mt-3">
              Há uma integração em andamento, caso esteja demorando muito,
              verifique se você executou todos os passos abaixo:
            </h3>
            <h4 class="subtitle mt-2">
              1. Criar um grupo ou canal no Telegram
            </h4>
            <h4 class="subtitle mt-2">
              2. Configurar como canal ou grupo privado
            </h4>
            <h4 class="subtitle mt-2">
              3. Adicione o bot "Greenn", com o username "greenn_relacionamento_bot" ao seu grupo do
              telegram, com a permissão de admin!
            </h4>
            <h4 class="subtitle mt-2">
              4. Envie o código "{{
                AllTelegramIntegrations.filter((i) => i.status === "draft")[0]
                  .code_integration
              }}" no seu grupo para confirmar a integração!
            </h4>

            <div
              class="editar mt-3"
              @click="
                removeTelegramIntegration(
                  AllTelegramIntegrations.filter(
                    (i) => i.status === 'draft' && i.rule
                  )[0],
                  true
                )
              "
            >
              Cancelar integração
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-telegram-update" hide-footer size="xl">
      <div class="AllContent">
        <div class="flexHeader">
          <div>
            <div class="title title-v2">Atualização com o Telegram</div>
            <div class="subtitle">É rápido e fácil</div>
          </div>
        </div>

        <div>
          <div class="body" style="display: flex; flex-direction: column">
            <h3 class="title mt-3">
              Instruções para atualizações de bots já existentes:
            </h3>
            <h4 class="subtitle" style="margin-top: 20px; margin-bottom: 200px;">
              1. Adicione o bot "Greenn", com o username "greenn_relacionamento_bot" ao seu grupo do
              telegram, com a permissão de admin!
            </h4>
          </div>
          <button @click="telegramHasUpdate" class="mt-2 btn-criar">
            Feito!
          </button>
        </div>
      </div>
    </b-modal>
    <ModalDeletarTelegram
      ref="modalDeleteTelegram"
      :listIntegrations="listIntegrationsTelegram"
    />
  </div>
  </SiteTemplateV2>
  <SiteTemplate v-else>
    <div class="createClass">
      <div class="container">
        <modal-upload :data="data"></modal-upload>
        <modal-categoria></modal-categoria>
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5" data-anima="top">
            <div class="navigate">
              <div class="title title-v2">
                {{ AllCourses.title }}
                <button
                  id="viewModuleLesson"
                  class="viewModuleLesson"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.375 8.75L12.5 5.625L9.375 2.5"
                      stroke="#C4C4C4"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                      stroke="#C4C4C4"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7956 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H9V16H5C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16H11V14H17C17.7956 14 18.5587 13.6839 19.1213 13.1213C19.6839 12.5587 20 11.7956 20 11V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8946 17.2652 12 17 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V11Z"
                        fill="#333333"
                      />
                    </svg>
                    Dados do Curso
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.47 16.82L20.47 12.96L17.32 1.37003C17.2519 1.11437 17.0853 0.896104 16.8567 0.76303C16.628 0.629955 16.3559 0.592918 16.1 0.66003L12.23 1.66003C12.1376 1.55767 12.0251 1.47552 11.8995 1.41873C11.7739 1.36194 11.6379 1.33174 11.5 1.33003H1.5C1.23478 1.33003 0.98043 1.43539 0.792893 1.62292C0.605357 1.81046 0.5 2.06481 0.5 2.33003V18.33C0.5 18.5952 0.605357 18.8496 0.792893 19.0371C0.98043 19.2247 1.23478 19.33 1.5 19.33H11.5C11.7652 19.33 12.0196 19.2247 12.2071 19.0371C12.3946 18.8496 12.5 18.5952 12.5 18.33V10.33L14.7 18.55C14.7586 18.7682 14.8893 18.9601 15.0709 19.0945C15.2525 19.2288 15.4742 19.2978 15.7 19.29C15.7864 19.2999 15.8736 19.2999 15.96 19.29L20.79 18C20.9177 17.9659 21.0374 17.9068 21.1421 17.8261C21.2468 17.7454 21.3344 17.6448 21.4 17.53C21.5055 17.3088 21.5302 17.0576 21.47 16.82ZM5.47 17.37H2.47V15.37H5.47V17.37ZM5.47 13.37H2.47V7.37003H5.47V13.37ZM5.47 5.37003H2.47V3.37003H5.47V5.37003ZM10.47 17.37H7.47V15.37H10.47V17.37ZM10.47 13.37H7.47V7.37003H10.47V13.37ZM10.47 5.37003H7.47V3.37003H10.47V5.37003ZM12.72 3.63003L15.62 2.85003L16.14 4.78003L13.24 5.56003L12.72 3.63003ZM15.31 13.29L13.76 7.49003L16.66 6.71003L18.21 12.51L15.31 13.29ZM16.31 17.15L15.79 15.22L18.69 14.44L19.21 16.37L16.31 17.15Z"
                        fill="#333333"
                      />
                    </svg>
                    Módulos e Aulas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="26"
                      height="22"
                      viewBox="0 0 26 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                        stroke="#333333"
                        stroke-width="2.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Turmas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.43496 22.9609H16.6119H8.43496ZM12.5234 22.9609V16.4194V22.9609ZM19.065 10.6955C19.065 8.10753 19.0609 3.81517 19.0588 2.51862C19.0588 2.30175 18.9727 2.09377 18.8193 1.94042C18.666 1.78708 18.458 1.70093 18.2411 1.70093L6.80417 1.71422C6.58775 1.71421 6.38016 1.80001 6.22688 1.95281C6.07361 2.1056 5.98716 2.31293 5.98648 2.52935C5.98648 4.09216 5.97984 9.13067 5.97984 10.6955C5.97984 13.9806 10.2216 16.4194 12.5214 16.4194C14.8211 16.4194 19.065 13.9806 19.065 10.6955ZM19.065 10.6955C21.4388 10.6955 23.1534 7.79374 23.1534 4.97169V4.154H19.065V10.6955ZM5.98188 4.154H1.89343V4.97169C1.89343 7.79374 3.60803 10.6955 5.98188 10.6955V4.154Z"
                        stroke="#333333"
                        stroke-width="1.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Certificado
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>

              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 6H21"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Vendas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>

              <div class="spaceBtn">
                <toggle-button
                  :sync="true"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  @change="enventSwitchStatus"
                  v-model="myDataVariable"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5" data-anima="top">
            <div class="containerWizzard">
              <div v-if="toAddClass === false" class="title title-v2">
                Minhas Turmas
                <svg
                  width="26"
                  height="22"
                  viewBox="0 0 26 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                    stroke="#333333"
                    stroke-width="2.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div v-else class="title">
                {{ textAction }}
                <svg
                  width="26"
                  height="22"
                  viewBox="0 0 26 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 20V18C17 16.9391 16.5786 15.9217 15.8284 15.1716C15.0783 14.4214 14.0609 14 13 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M21 7V13M24 10H18M13.5 6C13.5 8.20914 11.7091 10 9.5 10C7.29086 10 5.5 8.20914 5.5 6C5.5 3.79086 7.29086 2 9.5 2C11.7091 2 13.5 3.79086 13.5 6Z"
                    stroke="#333333"
                    stroke-width="2.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div class="containerWizzardInputs animationOpacity2">
              <b-table
                :fields="fieldsx"
                show-empty
                small
                stacked="md"
                :items="AllClass"
                :tbody-tr-class="rowClass"
                :filter-included-fields="filterOn"
                v-if="toAddClass === false"
              >
                <template #empty>
                  <p class="title text-center-empty">{{ emptyText }}</p>
                </template>
                <template #emptyfiltered>
                  <p class="title text-center-empty">{{ emptyText }}</p>
                </template>

                <template #cell(title)="row">
                  <div @click="howToEdit(row.item)" class="text pointer">
                    {{ row.item.title }}
                  </div>
                </template>
                <template #cell(students_count)="row">
                  <div @click="howToEdit(row.item)" class="text pointer">
                    {{ row.item.students_count }} Alunos
                  </div>
                </template>
                <template #cell(updated_at)="row">
                  <div
                    class="text pointer flexItem"
                    style="white-space: nowrap"
                  >
                    {{ row.item.updated_at | moment("DD/MM/YYYY HH:mm") }}
                  </div>
                </template>
                <template #cell(created_at)="row">
                  <div class="actionData">
                    <div class="editar" @click="howToEdit(row.item)">
                      Editar
                    </div>
                    <div class="deletar" @click="deleteClass(row.item.id)">
                      Deletar
                    </div>
                  </div>
                </template>
              </b-table>
              <div v-else>
                <div class="spaceInputs">
                  <b-form-group label="Nome da Turma" label-for="name-aula">
                    <b-form-input
                      v-model="nameClass"
                      placeholder="Nome da Turma"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <span v-if="toEditId !== ''">
                  <div class="spaceInputs">
                    <b-form-group
                      label="Liberação da Turma"
                      label-for="name-aula"
                    >
                      <button @click="openModalTurma" class="btnListModule">
                        Configurar Turma
                      </button>
                    </b-form-group>
                  </div>
                  <div class="spaceInputs">
                    <b-form-group
                      label="Liberação de Módulos"
                      label-for="name-aula"
                    >
                      <button @click="openModalModule" class="btnListModule">
                        Configurar Modulos
                      </button>
                    </b-form-group>
                  </div>
                  <div class="spaceInputs">
                    <b-form-group
                      label="Liberação de Aulas"
                      label-for="name-aula"
                    >
                      <button @click="openModalLesson" class="btnListModule">
                        Configurar Aulas
                      </button>
                    </b-form-group>
                  </div>
                  <div class="spaceInputs">
                    <b-form-group
                      label="Liberação do Telegram"
                      label-for="name-aula"
                    >
                      <button @click="openModalTelegram" class="btnListModule">
                        Configurar Telegram
                      </button>
                    </b-form-group>
                  </div>
                </span>

                <div class="spaceInputs">
                  <button class="btn-cancela" @click.prevent="notSaveClass()">
                    Voltar
                  </button>
                  <button class="btn-criar" @click.prevent="createClass()">
                    Salvar esta Turma
                  </button>
                </div>
              </div>
            </div>
            <div class="spaceInputs" v-if="toAddClass === false">
              <b-form-group class="paddingNewClass">
                <div class="btn-newClass" @click="addNewClass()">
                  <p class="text">Adicionar mais uma Turma</p>
                </div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal id="modal-turma-liberation" hide-footer size="xl">
        <div class="AllContent">
          <div class="flexHeader">
            <div>
              <div class="title title-v2">Meu Curso</div>
              <div class="subtitle">Configure a liberação do Curso</div>
            </div>
          </div>
          <div class="flexHeader2">
            <div></div>
            <!-- <div class="totalModules">{{ totalModules }} Cursos encontrados</div> -->
          </div>

          <div class="tableModule">
            <div class="header">
              <div>Nome do Curso</div>
              <div>Tipo</div>
              <div>Data Liberação</div>
              <div>Data Finalização</div>
              <div>Ação</div>
            </div>
            <div class="body">
              <div class="text">{{ AllCourses.title }}</div>
              <div
                class="text"
                v-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
              >
                Dias Após Inscrição
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
              >
                Data Fixa
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="AllCoursesLiberation.rule === null">
                Liberado
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
              >
                {{ AllCoursesLiberation.dinamicDate.daysToStart }} Dias
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
              >
                {{
                  AllCoursesLiberation.fixedDateStart
                    | moment("DD/MM/YYYY HH:mm")
                }}
              </div>

              <div class="text" v-if="AllCoursesLiberation.rule === null">
                Liberado
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'daysAfterInscription'"
              >
                {{ AllCoursesLiberation.dinamicDate.daysToEnd }} Dias
              </div>
              <div
                class="text"
                v-else-if="AllCoursesLiberation.rule === 'fixedDate'"
              >
                {{
                  AllCoursesLiberation.fixedDateEnd | moment("DD/MM/YYYY HH:mm")
                }}
              </div>

              <div class="editar" @click="openCursoEdit(AllCourses)">
                Gerenciar
              </div>
            </div>
          </div>
        </div>
        <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
          <div class="modalModuleLiberation">
            <svg
              @click="editModuleLiberation = false"
              class="close"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
                stroke="#B5B9C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="title title-v2">Liberação do Curso</div>
            <div class="space animationOpacity2">
              <b-form-group label="Tipo de Liberação" label-for="name-module">
                <b-form-select
                  v-model="selectedType"
                  :options="optionsType"
                  @input="resetInputs"
                ></b-form-select>
              </b-form-group>
            </div>
            <br />
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group label="Data de Liberação" label-for="name-module">
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscription"
                  placeholder="Ex: 15 Dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Data de Finalização(Opcional)"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscriptionEnd"
                  placeholder="Ex: 30 Dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group label="Data de Liberação" label-for="name-module">
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataInit"
                  placeholder="Ex: 15/04/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data de Finalização(Opcional)"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataFim"
                  placeholder="Ex: 15/05/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="space">
              <button class="btn-salvar" @click="salvarEditarCourse">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-modules-liberation" hide-footer size="xl">
        <div class="AllContent">
          <div class="flexHeader">
            <div>
              <div class="title title-v2">Listagem de Módulos</div>
              <div class="subtitle">Configure suas liberações</div>
            </div>
          </div>
          <div class="flexHeader2">
            <div></div>
            <div class="totalModules">
              {{ totalModules }} Módulos encontrados
            </div>
          </div>

          <div class="tableModule">
            <div class="header">
              <div>Nome do Módulo</div>
              <div>Tipo</div>
              <div>Data Liberação</div>
              <div>Data Finalização</div>
              <div>Ação</div>
            </div>
            <div class="body" v-for="modules in AllModules" :key="modules.id">
              <div class="text">{{ modules.title }}</div>
              <div class="text" v-if="modules.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="modules.rule.rule === 'daysAfterInscription'"
              >
                Dias Após Inscrição
              </div>
              <div class="text" v-else-if="modules.rule.rule === 'fixedDate'">
                Data Fixa
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="modules.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="modules.rule.rule === 'daysAfterInscription'"
              >
                {{ modules.rule.dinamicDate.daysToStart }} Dias
              </div>
              <div class="text" v-else-if="modules.rule.rule === 'fixedDate'">
                {{ modules.rule.fixedDateStart | moment("DD/MM/YYYY HH:mm") }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="modules.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="
                  modules.rule.rule === 'daysAfterInscription' &&
                  modules.rule.dinamicDate.daysToEnd === 0
                "
              >
                {{ modules.rule.dinamicDate.daysToEnd }} Dias
              </div>
              <div
                class="text"
                v-else-if="modules.rule.rule === 'daysAfterInscription'"
              >
                {{ modules.rule.dinamicDate.daysToEnd }} Dias
              </div>
              <div
                class="text"
                v-else-if="
                  modules.rule.rule === 'fixedDate' && modules.rule.fixedDateEnd
                "
              >
                {{ modules.rule.fixedDateEnd | moment("DD/MM/YYYY HH:mm") }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="editar" @click="openModuleEdit(modules)">
                Gerenciar
              </div>
            </div>
          </div>
        </div>
        <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
          <div class="modalModuleLiberation">
            <svg
              @click="editModuleLiberation = false"
              class="close"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
                stroke="#B5B9C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="title title-v2">Liberação de Módulo</div>
            <div class="space animationOpacity2">
              <b-form-group label="Tipo de Liberação" label-for="name-module">
                <b-form-select
                  v-model="selectedType"
                  :options="optionsType"
                ></b-form-select>
              </b-form-group>
            </div>
            <br />
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group label="Data de Liberação" label-for="name-module">
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscription"
                  placeholder="Ex: 15 Dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Data de Finalização(Opcional)"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscriptionEnd"
                  placeholder="Ex: 30 Dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group label="Data de Liberação" label-for="name-module">
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataInit"
                  placeholder="Ex: 15/04/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data de Finalização(Opcional)"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataFim"
                  placeholder="Ex: 15/05/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="space">
              <button class="btn-salvar" @click="salvarEditarModule">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-lesson-liberation" hide-footer size="xl">
        <div class="AllContent">
          <div class="flexHeader">
            <div>
              <div class="title title-v2">Listagem de Aulas</div>
              <div class="subtitle">Configure suas liberações</div>
            </div>
          </div>
          <div class="flexHeader2">
            <div>
              <b-form-select
                @change="selectedModuleinLesson"
                v-model="selectedModuleToClass"
                class="select-filter-options"
              >
                <b-form-select-option
                  v-for="(options, x) in AllModules"
                  :key="x"
                  :value="options.id"
                  >{{ options.title }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="totalModules">{{ totalLesson }} Aulas encontradas</div>
          </div>

          <div class="tableModule">
            <div class="header">
              <div>Nome da Aula</div>
              <div>Tipo</div>
              <div>Data Liberação</div>
              <div>Data Finalização</div>
              <div>Ação</div>
            </div>
            <div class="body" v-for="lesson in AllLessons" :key="lesson.id">
              <div class="text">{{ lesson.title }}</div>

              <div class="text" v-if="lesson.rule == null">Liberado</div>
              <div
                class="text"
                v-else-if="lesson.rule.rule === 'daysAfterInscription'"
              >
                Dias Após Inscrição
              </div>
              <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
                Data Fixa
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="lesson.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="lesson.rule.rule === 'daysAfterInscription'"
              >
                {{ lesson.rule.dinamicDate.daysToStart }} Dias
              </div>
              <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
                {{ lesson.rule.fixedDateStart | moment("DD/MM/YYYY HH:mm") }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="lesson.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="lesson.rule.rule === 'daysAfterInscription'"
              >
                {{ lesson.rule.dinamicDate.daysToEnd }} Dias
              </div>
              <div class="text" v-else-if="lesson.rule.rule === 'fixedDate'">
                {{ lesson.rule.fixedDateEnd | moment("DD/MM/YYYY HH:mm") }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="editar" @click="openLessonEdit(lesson)">
                Gerenciar
              </div>
            </div>
          </div>
        </div>
        <div class="fundoModalModuleLiberation" v-if="editModuleLiberation">
          <div class="modalModuleLiberation">
            <svg
              @click="editModuleLiberation = false"
              class="close"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
                stroke="#B5B9C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="title">Liberação de Aulas</div>
            <div class="space animationOpacity2">
              <b-form-group label="Tipo de Liberação" label-for="name-module">
                <b-form-select
                  v-model="selectedType"
                  :options="optionsType"
                ></b-form-select>
              </b-form-group>
            </div>
            <br />
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group label="Data de Liberação" label-for="name-module">
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscription"
                  placeholder="Ex: 15 Dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'daysAfterInscription'"
            >
              <b-form-group
                label="Data de Finalização(Opcional)"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'####'"
                  v-model="daysAfterInscriptionEnd"
                  placeholder="Ex: 30 Dias"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group label="Data de Liberação" label-for="name-module">
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataInit"
                  placeholder="Ex: 15/04/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="space animationOpacity2"
              v-if="selectedType === 'fixedDate'"
            >
              <b-form-group
                label="Data de Finalização(Opcional)"
                label-for="name-module"
              >
                <b-form-input
                  v-mask="'##/##/#### ##:##'"
                  v-model="dataFim"
                  placeholder="Ex: 15/05/2021 15:45"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="space">
              <button class="btn-salvar" @click="salvarEditarLesson">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-telegram-liberation" hide-footer size="xl">
        <div class="AllContent">
          <div class="flexHeader">
            <div>
              <div class="title title-v2">Integração com o Telegram</div>
              <div class="subtitle">Configure suas liberações</div>
              <button
                v-if="showBtnTelegram && !AllTelegramIntegrations.length"
                @click="initTelegramIntegration"
                class="btn-criar"
              >
                Iniciar integração
              </button>
            </div>
          </div>

          <div
            v-if="
              AllTelegramIntegrations.filter(
                (i) => i.status === 'draft' && !i.rule
              ).length >= 1
            "
          >
            <div class="body" style="display: flex; flex-direction: column">
              <h3 class="title mt-3">
                Instruções para continuar a integração:
              </h3>
              <h4 class="subtitle mt-2">
                1. Criar um grupo ou canal no Telegram
              </h4>
              <h4 class="subtitle mt-2">
                2. Configurar como canal ou grupo privado
              </h4>
              <h4 class="subtitle mt-2">
                3. Adicione o bot "Greenn", com o username "greenn_relacionamento_bot" ao seu grupo do
                telegram, com a permissão de admin!
              </h4>
              <h4 class="subtitle mt-2">
                4. Envie o código "{{
                  AllTelegramIntegrations.filter((i) => i.status === "draft")[0]
                    .code_integration
                }}" no seu grupo para confirmar a integração!
              </h4>

              <h4 class="subtitle mt-2">
                5. Configure a data de Liberação (Opcional)
              </h4>

              <div class="space animationOpacity2">
                <b-form-group label="Tipo de Liberação" label-for="name-module">
                  <b-form-select
                    v-model="selectedType"
                    :options="optionsType"
                  ></b-form-select>
                </b-form-group>
              </div>

              <div
                class="space animationOpacity2"
                v-if="selectedType === 'daysAfterInscription'"
              >
                <b-form-group label="Data de Liberação" label-for="name-module">
                  <b-form-input
                    v-mask="'####'"
                    v-model="daysAfterInscription"
                    placeholder="Ex: 15 Dias"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="space animationOpacity2"
                v-if="selectedType === 'daysAfterInscription'"
              >
                <b-form-group
                  label="Data de Finalização(Opcional)"
                  label-for="name-module"
                >
                  <b-form-input
                    v-mask="'####'"
                    v-model="daysAfterInscriptionEnd"
                    placeholder="Ex: 30 Dias"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="space animationOpacity2"
                v-if="selectedType === 'fixedDate'"
              >
                <b-form-group label="Data de Liberação" label-for="name-module">
                  <b-form-input
                    v-mask="'##/##/#### ##:##'"
                    v-model="dataInit"
                    placeholder="Ex: 15/04/2021 15:45"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="space animationOpacity2"
                v-if="selectedType === 'fixedDate'"
              >
                <b-form-group
                  label="Data de Finalização(Opcional)"
                  label-for="name-module"
                >
                  <b-form-input
                    v-mask="'##/##/#### ##:##'"
                    v-model="dataFim"
                    placeholder="Ex: 15/05/2021 15:45"
                  ></b-form-input>
                </b-form-group>
              </div>

              <button @click="salvarTelegramEdit" class="mt-2 btn-criar">
                Finalizar integração
              </button>
            </div>
          </div>
          <div
            v-if="
              AllTelegramIntegrations.filter((i) => i.status === 'published')
                .length >= 1
            "
            class="tableModule"
          >
            <div class="header">
              <div>Nome</div>
              <div>Tipo</div>
              <div>Data Liberação</div>
              <div>Data Finalização</div>
              <div>Ação</div>
            </div>
            <div
              class="body"
              v-for="telegram in AllTelegramIntegrations"
              :key="telegram.id"
            >
              <div class="text">{{ telegram.name }}</div>

              <div class="text" v-if="telegram.rule === null">Liberado</div>
              <div class="text" v-else-if="telegram.rule === 'dinamic'">
                Dias Após Inscrição
              </div>
              <div class="text" v-else-if="telegram.rule === 'fixed'">
                Data Fixa
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="telegram.rule === null">Liberado</div>
              <div class="text" v-else-if="telegram.rule === 'dinamic'">
                {{ telegram.dinamic_date.daysToStart }} Dias
              </div>
              <div class="text" v-else-if="telegram.rule === 'fixed'">
                {{
                  telegram.dinamic_date.fixedDateStart
                    | moment("DD/MM/YYYY HH:mm")
                }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div class="text" v-if="telegram.rule === null">Liberado</div>
              <div
                class="text"
                v-else-if="
                  telegram.rule === 'dinamic' &&
                  telegram.dinamic_date.daysToEnd &&
                  telegram.dinamic_date.daysToEnd === 0
                "
              >
                {{ telegram.dinamic_date.daysToEnd }} Dias
              </div>
              <div class="text" v-else-if="telegram.rule === 'dinamic'">
                {{ telegram.dinamic_date.daysToEnd }} Dias
              </div>
              <div class="text" v-else-if="telegram.rule === 'fixed'">
                {{
                  telegram.dinamic_date.fixedDateEnd
                    | moment("DD/MM/YYYY HH:mm")
                }}
              </div>
              <div class="text" v-else>Liberado</div>

              <div style="display: flex; flex-wrap: nowrap;">
                <div class="editar" @click="removeTelegramIntegration(telegram)">
                  Remover
                </div>
                <div v-if="needUpdateBotTegram(telegram)" class="atualizar ml-1" @click="openModalTelegramUpdate(telegram)">
                  Atualizar
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              AllTelegramIntegrations.filter(
                (i) => i.status === 'draft' && i.rule
              ).length >= 1
            "
            class="tableModule"
          >
            <div class="body" style="display: flex; flex-direction: column">
              <h3 class="title mt-3">
                Há uma integração em andamento, caso esteja demorando muito,
                verifique se você executou todos os passos abaixo:
              </h3>
              <h4 class="subtitle mt-2">
                1. Criar um grupo ou canal no Telegram
              </h4>
              <h4 class="subtitle mt-2">
                2. Configurar como canal ou grupo privado
              </h4>
              <h4 class="subtitle mt-2">
                3. Adicione o bot "Greenn", com o username "greenn_relacionamento_bot" ao seu grupo do
                telegram, com a permissão de admin!
              </h4>
              <h4 class="subtitle mt-2">
                4. Envie o código "{{
                  AllTelegramIntegrations.filter((i) => i.status === "draft")[0]
                    .code_integration
                }}" no seu grupo para confirmar a integração!
              </h4>

              <div
                class="editar mt-3"
                @click="
                  removeTelegramIntegration(
                    AllTelegramIntegrations.filter(
                      (i) => i.status === 'draft' && i.rule
                    )[0],
                    true
                  )
                "
              >
                Cancelar integração
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-telegram-update" hide-footer size="xl">
        <div class="AllContent">
          <div class="flexHeader">
            <div>
              <div class="title title-v2">Atualização com o Telegram</div>
              <div class="subtitle">É rápido e fácil</div>
            </div>
          </div>

          <div>
            <div class="body" style="display: flex; flex-direction: column">
              <h3 class="title mt-3">
                Instruções para atualizações de bots já existentes:
              </h3>
              <h4 class="subtitle mt-2" style="margin-top: 20px; margin-bottom: 200px;">
                1. Adicione o bot "Greenn", com o username "greenn_relacionamento_bot" ao seu grupo do
                telegram, com a permissão de admin!
              </h4>

              <button @click="telegramHasUpdate" class="mt-2 btn-criar">
                Feito!
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <ModalDeletarTelegram
        ref="modalDeleteTelegram"
        :listIntegrations="listIntegrationsTelegram"
      />
    </div>
  </SiteTemplate>
</template>
<script>
// import Vue from "vue";
import SiteTemplate from "@/templates/SiteTemplate.vue";
import SiteTemplateV2 from "@/templates/SiteTemplateV2.vue";

import ModalUpload from "@/components/ModalUpload.vue";
import ModalCategoria from "@/components/ModalCategoria.vue";
import ModalDeletarTelegram from "@/components/ModalDeletarTelegram.vue";
import { ToggleButton } from "vue-js-toggle-button";
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();
import ClassIntegrationService from "@/services/resources/ClassIntegrationService";
const serviceClassIntegration = ClassIntegrationService.build();
import LiberationService from "@/services/resources/LiberationService";
const serviceLiberation = LiberationService.build();
import moment from "moment";
import notify from "@/services/libs/notificacao";

export default {
  components: {
    SiteTemplate,
    SiteTemplateV2,
    ModalUpload,
    ModalCategoria,
    ToggleButton,
    ModalDeletarTelegram,
  },
  data() {
    return {
      beforeDataInit: "",
      showBtnTelegram: false,
      nameCourse: "teste",
      nameModulo: "",
      resumoCourse: "",
      descricaoCourse: "",
      categoryCourse: "",
      btnText: "Criar curso agora",
      titleText: "Crie o seu curso",
      step: 0,
      AllCategory: 1,
      data: "introducao",
      midiaintroducao: "",
      midiacapa: "",
      midiaaulas: "",
      cursoAtual: "",
      myDataVariable: true,
      existParans: false,
      editorOption: {
        // Some Quill options...
      },
      rowClass: "row-table-body",
      emptyText: "Sem Resultados",
      filterOn: [],
      fieldsx: [
        { key: "title", label: "Turma" },
        { key: "students_count", label: "Qtd Alunos" },
        { key: "updated_at", label: "Criada em" },
        { key: "created_at", label: "Ações" },
      ],
      AllClass: [],
      toAddClass: false,
      nameClass: "",
      toEdit: false,
      toEditId: "",
      class_id: null,
      textAction: "Criar Turma",
      AllModules: [],
      totalModules: 0,
      editModuleLiberation: false,
      selectedModuleEdit: [],
      selectedLessonEdit: [],
      selectedTelegramEdit: [],
      dataInit: "",
      dataFim: "",
      existLiberation: {},
      selectedModuleToClass: {},
      getEditLessons: false,
      AllLessons: [],
      AllTelegramIntegrations: [],
      totalLesson: "",
      selectedType: "fixedDate",
      optionsType: [
        { value: null, text: "Liberado sem regra" },
        { value: "fixedDate", text: "Data Fixa" },
        { value: "daysAfterInscription", text: "Dias Após Cadastro " },
      ],
      daysAfterInscription: "",
      daysAfterInscriptionEnd: "",
      AllCourses: [],
      AllCoursesLiberation: {
        rule: null,
      },
      telegramToUpdate: {},
    };
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  methods: {
    needUpdateBotTegram(telegram) {
      const isPublished = telegram.status === 'published'
      const telegramIntegrationLastUpdate = telegram.updated_at;
      const limitDate = moment('2023-10-05 23:04:07', 'YYYY-MM-DD HH:mm:ss').format();
      const dateCheck = moment(telegramIntegrationLastUpdate).isBefore(limitDate)
      return isPublished && dateCheck;
    },
    telegramHasUpdate() {
      const telegramToUpdate = this.telegramToUpdate;
      serviceClassIntegration
        .update(telegramToUpdate)
        .then(() => {
          this.listIntegrationsTelegram();
          this.closeModalTelegramUpdate();
          notify("sucesso", "Integração Salva com Sucesso!");
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    viewCourse() {
      serviceCourse.read(this.cursoAtual + "/module").then((resp) => {
        //console.log("get modules", resp);
        if (resp.length === 0) {
          notify("erro", "Nenhum Módulo Configurado!");
          return false;
        }
        let routeData = this.$router.resolve("/curso/" + this.cursoAtual);
        window.open(routeData.href, "_blank");
      });
    },
    enventSwitchStatus() {
      var xxxStatus = "";
      if (this.myDataVariable === true) {
        xxxStatus = "published";
      } else {
        xxxStatus = "draft";
      }
      this.$root.$emit("loadOn");
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then(() => {
          this.$root.$emit("loadOff");
          this.getCourse();
        })
        .catch(() => {
          this.$root.$emit("loadOff");
        });
    },
    selectedModuleinLesson() {
      this.getLessons();
    },
    salvarEditarLesson() {
      if (
        this.existLiberation === null ||
        this.existLiberation.id === undefined
      ) {
        if (this.selectedType === null) {
          this.editModuleLiberation = false;
          this.selectedLessonEdit = [];
          return false;
        } else if (this.selectedType === "fixedDate") {
          var data1 = this.dataInit;
          data1 = data1.split("/");
          var data1hora = data1[2].split(" ");
          var data1result =
            data1hora[0] + "-" + data1[1] + "-" + data1[0] + " " + data1hora[1];

          var data2 = this.dataFim;
          if (data2) {
            data2 = data2.split("/");
            var data2hora = data2[2].split(" ");
            var data2result =
              data2hora[0] +
              "-" +
              data2[1] +
              "-" +
              data2[0] +
              " " +
              data2hora[1];
          } else {
            data2result = null;
          }

          var data = {
            id: "lesson/",
            rule: "fixedDate",
            fixedDateStart: moment(data1result).format("YYYY-MM-DD HH:mm"),
            fixedDateEnd:
              data2result === null
                ? null
                : moment(data2result).format("YYYY-MM-DD HH:mm"),
            lesson_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId,
          };
        } else if (this.selectedType === "daysAfterInscription") {
          data = {
            id: "lesson/",
            rule: "daysAfterInscription",
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            lesson_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId,
          };
        }

        serviceLiberation
          .postID(data)
          .then(() => {
            this.editModuleLiberation = false;
            this.selectedLessonEdit = [];
            this.getLessons();
          })
          .finally(() => {
            this.$root.$emit("loadOff");
          });
      } else {
        if (this.selectedType === null) {
          serviceLiberation
            .destroy("lesson/" + this.existLiberation.id)
            .then(() => {
              this.editModuleLiberation = false;
              this.selectedLessonEdit = [];
              this.getLessons();
            })
            .finally(() => {
              this.$root.$emit("loadOff");
            });
          return false;
        } else if (this.selectedType === "fixedDate") {
          var data1 = this.dataInit;
          data1 = data1.split("/");
          var data1hora = data1[2].split(" ");
          var data1result =
            data1hora[0] + "-" + data1[1] + "-" + data1[0] + " " + data1hora[1];

          var data2 = this.dataFim;
          if (data2) {
            data2 = data2.split("/");
            var data2hora = data2[2].split(" ");
            var data2result =
              data2hora[0] +
              "-" +
              data2[1] +
              "-" +
              data2[0] +
              " " +
              data2hora[1];
          } else {
            data2result = null;
          }

          var data = {
            id: "lesson/" + this.existLiberation.id,
            rule: "fixedDate",
            fixedDateStart: moment(data1result).format("YYYY-MM-DD HH:mm"),
            fixedDateEnd:
              data2result === null
                ? null
                : moment(data2result).format("YYYY-MM-DD HH:mm"),
            lesson_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId,
          };
        } else if (this.selectedType === "daysAfterInscription") {
          var data = {
            id: "lesson/" + this.existLiberation.id,
            rule: "daysAfterInscription",
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            lesson_id: this.selectedLessonEdit.id,
            course_class_id: this.toEditId,
          };
        }
        serviceLiberation
          .postID(data)
          .then(() => {
            this.editModuleLiberation = false;
            this.selectedLessonEdit = [];
            this.getLessons();
          })
          .finally(() => {
            this.$root.$emit("loadOff");
          });
      }
    },
    
    salvarTelegramEdit() {
      let formatToSaveThePayload = "YYYY-MM-DD HH:mm";
      let dateInitIsValid = moment(this.dataInit, "DD/MM/YYYY HH:mm").isValid();
      let dateEndIsValid = this.dataFim
        ? moment(this.dataFim, "DD/MM/YYYY HH:mm").isValid()
        : true; //Valida a data final se existir algo digitado
      let dateInitInscriptionIsValid = this.daysAfterInscription ? true : false;
      let theTwoDatesAreValid = dateInitIsValid && dateEndIsValid;
      if (this.selectedType === "fixedDate" && !theTwoDatesAreValid) {
        notify("erro", "Informe a data de liberação corretamente!");
        return;
      }

      if (
        this.selectedType === "daysAfterInscription" &&
        !dateInitInscriptionIsValid
      ) {
        notify("erro", "Informe a data de liberação corretamente!");
        return;
      }

      let payload = {
        id: this.AllTelegramIntegrations.filter((i) => i.status === "draft")[0]
          .id,
      };

      switch (this.selectedType) {
        case "fixedDate":
          payload.rule = "fixed";
          payload.dinamic_date = {
            fixedDateStart: moment(this.dataInit, "DD/MM/YYYY HH:mm").format(
              formatToSaveThePayload
            ),
            fixedDateEnd: this.dataFim
              ? moment(this.dataFim, "DD/MM/YYYY HH:mm").format(
                  formatToSaveThePayload
                )
              : null,
          };
          break;
        case "daysAfterInscription":
          payload.rule = "dinamic";
          payload.dinamic_date = {
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
          };
          break;
        default:
          break;
      }
      serviceClassIntegration
        .update(payload)
        .then(() => {
          this.listIntegrationsTelegram();
          notify("sucesso", "Integração Salva com Sucesso!");
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    salvarEditarModule() {
      let formatToSaveThePayload = "YYYY-MM-DD HH:mm";
      let dateInitIsValid = moment(this.dataInit, "DD/MM/YYYY HH:mm").isValid();
      let dateEndIsValid = this.dataFim
        ? moment(this.dataFim, "DD/MM/YYYY HH:mm").isValid()
        : true; //Valida a data final se existir algo digitado
      let theTwoDatesAreValid = dateInitIsValid && dateEndIsValid;
      if (this.selectedType === "fixedDate" && !theTwoDatesAreValid) {
        notify("erro", "Informe a data de liberação corretamente!");
        return;
      }

      const existLiberation = !(
        this.existLiberation === null || this.existLiberation.id === null
      );
      let payload = {
        id: existLiberation ? `module/${this.existLiberation.id}` : "module/",
        module_id: this.selectedModuleEdit.id,
        course_class_id: this.toEditId ?? this.class_id,
      };

      switch (this.selectedType) {
        case "fixedDate":
          payload.rule = "fixedDate";
          payload.fixedDateStart = moment(
            this.dataInit,
            "DD/MM/YYYY HH:mm"
          ).format(formatToSaveThePayload);
          payload.fixedDateEnd = this.dataFim
            ? moment(this.dataFim, "DD/MM/YYYY HH:mm").format(
                formatToSaveThePayload
              )
            : null;
          this.updateModuleLiberation(payload);
          break;
        case "daysAfterInscription":
          payload.rule = "daysAfterInscription";
          payload.daysToStart = parseInt(this.daysAfterInscription, 10);
          payload.daysToEnd = parseInt(this.daysAfterInscriptionEnd, 10);
          this.updateModuleLiberation(payload);
          break;
        default:
          if (existLiberation) {
            this.deleteModuleLiberation();
          }
          break;
      }
    },
    salvarEditarCourse() {
      if (
        this.existLiberation.id === null ||
        this.existLiberation.role === null
      ) {
        if (this.selectedType === null) {
          this.editModuleLiberation = false;
          this.selectedModuleEdit = [];
          return false;
        } else if (this.selectedType === "fixedDate") {
          var data1 = this.dataInit;
          data1 = data1.split("/");
          var data1hora = data1[2].split(" ");
          var data1result =
            data1hora[0] + "-" + data1[1] + "-" + data1[0] + " " + data1hora[1];

          var data2 = this.dataFim;
          var data2result = null;
          if (data2) {
            data2 = data2.split("/");
            var data2hora = data2[2].split(" ");
            var data2result =
              data2hora[0] +
              "-" +
              data2[1] +
              "-" +
              data2[0] +
              " " +
              data2hora[1];
            data2result = moment(data2result).format("YYYY-MM-DD HH:mm");
          }

          var data = {
            id: "course/",
            rule: "fixedDate",
            fixedDateStart: moment(data1result).format("YYYY-MM-DD HH:mm"),
            fixedDateEnd: data2result,
            course_id: this.AllCourses.id,
            course_class_id: this.toEditId,
          };
        } else if (this.selectedType === "daysAfterInscription") {
          var data = {
            id: "course/",
            rule: "daysAfterInscription",
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            course_id: this.AllCourses.id,
            course_class_id: this.toEditId,
          };
        }
        serviceLiberation
          .postID(data)
          .then(() => {
            this.editModuleLiberation = false;
            this.selectedModuleEdit = [];
            this.getCourse();
          })
          .finally(() => {
            this.$root.$emit("loadOff");
          });
      } else {
        if (this.selectedType === null) {
          serviceLiberation
            .destroy("course/" + this.existLiberation.id)
            .then(() => {
              this.editModuleLiberation = false;
              this.selectedModuleEdit = [];
              this.getCourse();
            })
            .finally(() => {
              this.$root.$emit("loadOff");
            });
          return false;
        } else if (this.selectedType === "fixedDate") {
          var data1 = this.dataInit;
          data1 = data1.split("/");
          var data1hora = data1[2].split(" ");
          var data1result =
            data1hora[0] + "-" + data1[1] + "-" + data1[0] + " " + data1hora[1];

          var data2 = this.dataFim;
          var data2result = null;
          if (data2) {
            data2 = data2.split("/");
            var data2hora = data2[2].split(" ");
            var data2result =
              data2hora[0] +
              "-" +
              data2[1] +
              "-" +
              data2[0] +
              " " +
              data2hora[1];
          }

          var data = {
            id: "course/" + this.existLiberation.id,
            rule: "fixedDate",
            fixedDateStart: moment(data1result).format("YYYY-MM-DD HH:mm"),
            fixedDateEnd: data2result
              ? moment(data2result).format("YYYY-MM-DD HH:mm")
              : null,
            course_id: this.AllCourses.id,
            course_class_id: this.toEditId,
          };
        } else if (this.selectedType === "daysAfterInscription") {
          var data = {
            id: "course/" + this.existLiberation.id,
            rule: "daysAfterInscription",
            daysToStart: parseInt(this.daysAfterInscription, 10),
            daysToEnd: parseInt(this.daysAfterInscriptionEnd, 10),
            course_id: this.AllCourses.id,
            course_class_id: this.toEditId,
          };
        }
        serviceLiberation
          .postID(data)
          .then(() => {
            this.editModuleLiberation = false;
            this.selectedModuleEdit = [];
            this.getCourse();
          })
          .finally(() => {
            this.$root.$emit("loadOff");
          });
      }
    },
    openLessonEdit(data) {
      this.editModuleLiberation = true;
      this.selectedLessonEdit = data;
      // console.log("data to selected", data);
      if (data.rule === null) {
        this.selectedType = null;
      } else {
        if (data.rule.rule === "daysAfterInscription") {
          this.selectedType = "daysAfterInscription";
          this.daysAfterInscription = data.rule.dinamicDate.daysToStart;
          this.daysAfterInscriptionEnd = data.rule.dinamicDate.daysToEnd;
        } else {
          this.selectedType = "fixedDate";
          this.dataInit = moment(data.rule.fixedDateStart).format(
            "DD-MM-YYYY HH:mm"
          );
          this.dataFim = moment(data.rule.fixedDateEnd).format(
            "DD-MM-YYYY HH:mm"
          );
        }
      }

      serviceLiberation
        .read(
          "lesson/" +
            this.selectedLessonEdit.id +
            "/course-class/" +
            this.toEditId
        )
        .then((resp) => {
          this.existLiberation = resp;
        })
        .catch(() => {
          this.existLiberation = null;
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    openCursoEdit(data) {
      this.editModuleLiberation = true;
      this.selectedCourseEdit = data;
      if (this.AllCoursesLiberation.rule === null) {
        this.selectedType = null;
      } else {
        if (this.AllCoursesLiberation.rule === "daysAfterInscription") {
          this.selectedType = "daysAfterInscription";
          this.daysAfterInscription =
            this.AllCoursesLiberation.dinamicDate.daysToStart;
          this.daysAfterInscriptionEnd =
            this.AllCoursesLiberation.dinamicDate.daysToEnd;
        } else {
          this.selectedType = "fixedDate";
          this.dataInit = moment(
            this.AllCoursesLiberation.fixedDateStart
          ).format("DD-MM-YYYY HH:mm");
          this.dataFim = moment(this.AllCoursesLiberation.fixedDateEnd).format(
            "DD-MM-YYYY HH:mm"
          );
        }
      }
      this.existLiberation = this.AllCoursesLiberation;
    },
    openModuleEdit(data) {
      this.editModuleLiberation = true;
      this.selectedModuleEdit = data;
      if (data.rule === null) {
        this.selectedType = null;
      } else {
        if (data.rule.rule === "daysAfterInscription") {
          this.selectedType = "daysAfterInscription";
          this.daysAfterInscription = data.rule.dinamicDate.daysToStart;
          this.daysAfterInscriptionEnd = data.rule.dinamicDate.daysToEnd;
        } else {
          this.selectedType = "fixedDate";
          this.dataInit = moment(data.rule.fixedDateStart).format(
            "DD-MM-YYYY HH:mm"
          );
          if (data.rule.fixedDataEnd) {
            this.dataFim = moment(data.rule.fixedDateEnd).format(
              "DD-MM-YYYY HH:mm"
            );
          } else {
            this.dataFim = null;
          }
        }
      }

      serviceLiberation
        .read(
          "module/" +
            this.selectedModuleEdit.id +
            "/course-class/" +
            this.toEditId
        )
        .then((resp) => {
          this.existLiberation = resp;
        })
        .catch(() => {
          this.existLiberation = null;
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    howToEdit(item) {
      this.addNewClass();
      this.toEdit = true;
      this.toEditId = item.id;
      this.class_id = item.id;
      this.nameClass = item.title;
      this.textAction = "Editar Turma";
    },
    openModalTurma() {
      (this.getEditLessons = false), this.getCourse();
      this.$root.$emit("bv::show::modal", "modal-turma-liberation", "#btnShow");
    },
    openModalModule() {
      (this.getEditLessons = false), this.getModules();
      this.$root.$emit(
        "bv::show::modal",
        "modal-modules-liberation",
        "#btnShow"
      );
    },
    openModalLesson() {
      this.getEditLessons = true;
      this.getModules();
      this.$root.$emit(
        "bv::show::modal",
        "modal-lesson-liberation",
        "#btnShow"
      );
    },
    openModalTelegram() {
      this.listIntegrationsTelegram();
      this.$root.$emit(
        "bv::show::modal",
        "modal-telegram-liberation",
        "#btnShow"
      );
    },
    openModalTelegramUpdate(telegramToUpdate) {
      this.telegramToUpdate = telegramToUpdate;
      this.$root.$emit(
        "bv::show::modal",
        "modal-telegram-update",
        "#btnShow"
      );
    },
    closeModalTelegramUpdate() {
      this.$root.$emit(
      "bv::hide::modal",
      "modal-telegram-update",
      "#btnShow"
      );
      this.telegramToUpdate = {};
    },
    removeTelegramIntegration(integration, cancel) {
      this.$refs.modalDeleteTelegram.open(integration, cancel);
    },
    getLessons() {
      this.$root.$emit("loadOn");
      serviceCourse
        .read(
          this.cursoAtual +
            "/module/" +
            this.selectedModuleToClass +
            "/lesson/course-class/" +
            this.toEditId +
            "/liberation-rule"
        )
        .then((resp) => {
          for (let i = 0; i < resp.length; i++) {
            if (resp[i].rule !== null) {
              resp[i].rule.dinamicDate = JSON.parse(resp[i].rule.dinamicDate);
            }
          }
          this.AllLessons = resp;
          this.totalLesson = resp.length;
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    getModules() {
      this.$root.$emit("loadOn");
      serviceCourse
        .read(
          this.cursoAtual +
            "/module/course-class/" +
            this.toEditId +
            "/liberation-rule"
        )
        .then((resp) => {
          for (let i = 0; i < resp.length; i++) {
            if (resp[i].rule !== null) {
              resp[i].rule.dinamicDate = JSON.parse(resp[i].rule.dinamicDate);
            }
          }
          this.AllModules = resp;
          this.totalModules = resp.length;
          if (this.getEditLessons === true) {
            this.selectedModuleToClass = resp[0].id;
            this.getLessons();
          }
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    deleteClass(id) {
      this.$root.$emit("loadOn");
      serviceCourse
        .destroy(this.cursoAtual + "/class/" + id)
        .then(() => {
          this.getClass();
          notify("sucesso", "Turma deletada com Sucesso!");
        })
        .catch((err) => {
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    initTelegramIntegration() {
      this.$root.$emit("loadOn");
      const class_id = this.toEditId;
      serviceClassIntegration
        .postID({ id: `?class_id=${class_id}&type=telegram` })
        .then(() => {
          this.listIntegrationsTelegram();
        })
        .catch((err) => {
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    listIntegrationsTelegram() {
      this.$root.$emit("loadOn");
      const id = this.toEditId;
      serviceClassIntegration
        .read({ id })
        .then((resp) => {
          this.AllTelegramIntegrations = resp.map((item) => {
            return {
              ...item,
              dinamic_date: item.dinamic_date
                ? JSON.parse(item.dinamic_date)
                : null,
            };
          });
          const itemPending = resp.filter((i) => i.status === "draft");
          if (itemPending.length) {
            this.selectedTelegramEdit = itemPending[0];
            (this.selectedTelegramEdit.dinamic_date = this.selectedTelegramEdit
              .dinamic_date
              ? JSON.parse(this.selectedTelegramEdit.dinamic_date)
              : null),
              this;
          } else {
            this.selectedTelegramEdit = [];
          }
          if (!resp.length) {
            this.showBtnTelegram = true;
          }
          this.$root.$emit("loadOff");
          this.$forceUpdate();
          // notify("sucesso", "Integração Telegram Iniciada!");
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        });
    },
    createClass() {
      if (this.toEdit === false) {
        //console.log("to save");
        this.$root.$emit("loadOn");
        serviceCourse
          .postID({ id: this.cursoAtual + "/class", title: this.nameClass })
          .then((resp) => {
            //console.log("create class", resp);
            this.$root.$emit("loadOff");
            this.toEdit = true;
            this.toEditId = resp.id;
            this.nameClass = resp.title;
            this.textAction = "Editar Turma";
            this.getClass();
            notify("sucesso", "Turma salva com Sucesso!");
          })
          .catch((err) => {
            //console.log(err);
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
            this.$root.$emit("loadOff");
          });
      } else {
        //console.log("to edit");
        this.$root.$emit("loadOn");
        serviceCourse
          .postID({
            id: this.cursoAtual + "/class/" + this.toEditId,
            title: this.nameClass,
          })
          .then(() => {
            this.toEdit = false;
            this.toEditId = "";
            this.notSaveClass();
            this.getClass();
            notify("sucesso", "Turma salva com Sucesso!");
          })
          .catch((err) => {
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
          })
          .finally(() => {
            this.$root.$emit("loadOff");
          });
      }
    },
    notSaveClass() {
      this.toAddClass = false;
    },
    addNewClass() {
      this.toAddClass = true;
      this.textAction = "Criar Turma";
    },
    pushToList(id) {
      this.$router.push("/config_curso/class/" + id);
    },
    getCourse() {
      this.$root.$emit("loadOn");
      let data = {
        id: this.cursoAtual,
      };
      serviceCourse.read(data).then((resp) => {
        this.AllCourses = resp;

        serviceLiberation
          .read(
            "course/" + this.AllCourses.id + "/course-class/" + this.toEditId
          )
          .then((resp) => {
            resp.dinamicDate = JSON.parse(resp.dinamicDate);
            this.AllCoursesLiberation = resp;
          })
          .catch((err) => {
            if (err.response.status === 404) {
              let data = {
                id: null,
                rule: null,
              };
              this.AllCoursesLiberation = data;
            }
          });
        this.$root.$emit("loadOff");
      });
    },
    getClass() {
      let params = this.$route.params.curso;
      //console.log(params);
      if (params === null || params === undefined || params === "") {
        this.$router.push("/course");
      } else {
        this.cursoAtual = params;
        this.existParans = false;
        this.$root.$emit("loadOn");
        serviceCourse.read(params + "/class").then((resp) => {
          //console.log("get class", resp);
          this.AllClass = resp;
          this.$root.$emit("loadOff");
          this.getCourse();
        });
      }
    },
    updateModuleLiberation(data) {
      serviceLiberation
        .postID(data)
        .then(() => {
          this.editModuleLiberation = false;
          this.selectedModuleEdit = [];
          this.getModules();
          notify("sucesso", "Módulo atualizado com Sucesso!");
        })
        .catch((e) => {
          notify("erro", "Erro ao atualizar módulo!" + e);
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    deleteModuleLiberation() {
      serviceLiberation
        .destroy("module/" + this.existLiberation.id)
        .then(() => {
          this.editModuleLiberation = false;
          this.selectedModuleEdit = [];
          this.getModules();
          this.$root.$emit("loadOff");
        })
        .catch(() => {
          this.$root.$emit("loadOff");
        });
    },
    resetInputs() {
      setTimeout(() => {
        this.dataInit = "";
        this.dataFim = "";
        this.daysAfterInscription = "";
        this.daysAfterInscriptionEnd = "";
        this.$forceUpdate();
      }, 400);
    },
  },
  mounted() {
    this.getClass();
  },
  watch: {
    selectedType() {
      this.resetInputs();
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.title-v2{
  font-family: "Montserrat Alternates" !important; 
  font-style: normal !important;
  font-weight: 800 !important;
}

.btn-criar {
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  width: 100%;
  height: 55px;
  margin-top: 20px;
}
#modal-telegram-liberation {
  header {
    border: none !important;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 45px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Montserrat;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
        .atualizar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          margin-left: 12px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
          cursor: pointer;
        }
      }
    }
  }
}
#modal-telegram-update {
  header {
    border: none !important;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 45px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Montserrat;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
        .atualizar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          margin-left: 12px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
          cursor: pointer;
        }
      }
    }
  }
}
#modal-lesson-liberation {
  header {
    border: none !important;
  }
  .select-filter-options {
    position: relative;
    top: 20px;
  }
  select {
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6d767e;
    overflow: hidden !important;
    resize: none !important;
  }
  select:hover {
    border: 1px solid var(--maincolor);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 45px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Montserrat;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
      }
    }
  }
}
#modal-modules-liberation {
  header {
    border: none !important;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        // margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Montserrat;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
      }
    }
  }
}

#modal-turma-liberation {
  header {
    border: none !important;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        // margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Montserrat;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
      }
    }
  }
}

.createClass {
  .btnListModule {
    height: 45px;
    width: 300px;
    background: var(--maincolor);
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    border: none;
    padding: 0 10px;
  }
  .actionData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .editar {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
      cursor: pointer;
    }
    .deletar {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #ff0c37;
      cursor: pointer;
    }
  }
  img.spaceDeleteSvg {
    margin-left: 10px;
    margin-right: 10px;
  }
  .paddingNewClass {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 10px;
  }
  .btn-cancela {
    background: #f7f7f7;
    border: 1px solid#f7f7f7;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #81858e;
    width: 100%;
    height: 55px;
    margin-top: 40px;
  }
  .btn-newClass {
    background: rgba(129, 133, 142, 0.03);
    border: 2px dashed #81858e;
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    cursor: pointer;
    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #81858e;
      display: flex;
      justify-content: center;
      margin-top: 17px;
    }
  }
  .flexItem {
    display: flex;
    width: 65%;
    justify-content: space-between;
  }
  .removeLink {
    text-decoration: none;
  }
  .table {
    margin-top: 40px;
    .pointer {
      cursor: pointer;
    }
    .table-sm th,
    .table-sm td {
      padding: 0.5rem !important;
    }
    th {
      border: none !important;
      font-family: Montserrat !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      letter-spacing: 0.2px !important;
      color: var(--fontcolor) !important;
    }
    th div {
      color: var(--fontcolor);
    }
    tr.row-table-body {
      line-height: 40px !important;
    }
    td {
      border-top: none !important;
      border-bottom: 1px solid #e5e5e5 !important;
    }
    .table thead th {
      border: none !important;
    }
    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .textArrow {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
      display: flex;
      img {
        padding-left: 20px;
      }
    }
  }
  .removeLink {
    text-decoration: none;
  }

  .disableLink {
    pointer-events: none;
  }
  .ql-editor {
    min-height: 100px !important;
  }
  .navigate {
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor);
      word-break: break-all;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(33, 51, 210, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        border: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #2133d2;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Montserrat;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Montserrat;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Montserrat !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputs {
      margin-bottom: 30px;
      .textInfo {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #81858e;
        margin-top: 5px;
      }
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 20px;
      }
      .btn-openModal {
        background: var(--maincolortrans);
        border: 2px dashed var(--maincolor);
        box-sizing: border-box;
        border-radius: 5px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--maincolor);
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .gerenciarCat {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: var(--maincolor);
        position: absolute;
        right: 9em;
        cursor: pointer;
      }
      select {
        border: 1px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 5px;
        height: 55px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #6d767e;
        overflow: hidden !important;
        resize: none !important;
      }
      input,
      textarea {
        border: 1px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 5px;
        height: 55px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        overflow: hidden !important;
        resize: none !important;
        background: white;
      }
      input:hover,
      select:hover,
      textarea:hover,
      input:focus,
      select:focus,
      textarea:focus {
        border: 1px solid var(--maincolor);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }
      label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--fontcolor2);
      }
    }
  }
  .spaceInputsCreate {
    display: flex;
    justify-content: flex-end;
    position: relative;
    left: 5em;
    top: -2em;
    .btn-criar {
      background: var(--maincolor);
      border: 1px solid var(--maincolor);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      width: 100%;
      height: 55px;
      margin-top: 40px;
      width: 250px;
    }
  }
  .containerWizzardInputs {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    margin-top: 50px;
    .paddingNewModule {
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 10px;
    }
    .btn-newModule {
      background: rgba(129, 133, 142, 0.03);
      border: 2px dashed #81858e;
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      cursor: pointer;
      .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #81858e;
        display: flex;
        justify-content: center;
        margin-top: 17px;
      }
    }
    .removemb {
      margin-bottom: 0px !important;
    }
    .actionModule {
      display: flex;
      justify-content: space-between;
      .titleModule {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--maincolor);
      }
      svg {
        position: relative;
        left: 24px;
        z-index: 2;
      }
      button.btn.dropdown-toggle.btn-secondary,
      button.btn.dropdown-toggle.btn-secondary:hover,
      button.btn.dropdown-toggle.btn-secondary:focus,
      button.btn.dropdown-toggle.btn-secondary:active,
      button.btn.dropdown-toggle.btn-secondary:focus-within,
      button.btn.dropdown-toggle.btn-secondary:focus-visible {
        background: rgba(255, 255, 255, 0) !important;
        border: rgba(0, 0, 0, 0) !important;
        box-shadow: none !important;
        z-index: 9;
        color: #303e3e00 !important;
      }
      a.dropdown-item {
        font-family: Montserrat !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #333 !important;
      }
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: #333 !important;
        text-decoration: none !important;
        background-color: #fff !important;
      }
    }
    .lineDiv {
      background: #ededf0;
      transform: matrix(1, 0, 0, -1, 0, 0);
      height: 0.5px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .spaceModuleCard {
      width: 100%;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 32px;
      margin-bottom: 20px;
    }
  }
  .containerWizzard {
    text-align: center;
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      align-items: center;
      color: var(--fontcolor);
    }
  }
  .containerx {
    width: 98%;
  }
}
</style>
